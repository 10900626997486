<!-- 账单列表 -->
<template>
  <div>
    <blockTitle title="账单">
      <template #customContent>
        <el-button class="ml-4" type="primary" size="mini" @click="createBill"
        >创建账单
        </el-button>
      </template>
    </blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <billListContent></billListContent>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import billListContent from 'components/finance/billListContent'

export default {
  name: 'billList',
  data () {
    return {}
  },
  components: {
    billListContent
  },
  methods: {
    createBill () {
      this.$router.push({ name: 'billEditor' })
    }
  },
  mounted () {
  }
}
</script>
