<!-- 账单列表内容 -->
<template>
  <AppTable
      ref="AppTable"
      controller="bill"
      :tableColumns="getTableColumns()"
      @on-handleSelection-change="onHandleSelectionChange"
      :defaultGetData="true"
      :request-params="requestParams"
  >
    <template #left-operator>
      <div class="h-8 flex flex-row">
        <el-button-group>
          <el-button
              v-for="(item, index) in stateList"
              :key="index"
              size="mini"
              round
              class="select-none"
              :type="requestParams.states === item.type ? 'primary' : ''"
              @click="changeStateType(item.type)"
          >
            {{ item.label }}
          </el-button>
        </el-button-group>
      </div>
    </template>
    <template #money="{ row }"> {{ row.totalFee }} 元</template>
    <template #offDtm="{ row }">
      {{ row.offDtm }}
    </template>
    <template #payDtm="{ row }">
      {{ row.payDtm }}
    </template>
    <template #operator="{ row }">
      <el-dropdown
          size="small"
          split-button
          @click="clickDropDown(row)"
          v-if="requestParams.states === 1"
      >
        <template> 出账</template>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div
                class="w-24 h-10 flex align-items-center"
                @click="billOff(row)"
            >
              <i class="el-icon-success pr-1.5 text-base text-green-700"></i>
              出账
            </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div
                class="w-24 h-10 flex align-items-center"
                @click="editBill(row)"
            >
              <i class="el-icon-edit pr-1.5 text-base"></i>
              编辑账单
            </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div
                class="w-24 h-10 flex align-items-center"
                @click="toBillContent(row)"
            >
              <i class="el-icon-wallet pr-1.5 text-base"></i>
              查看明细
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div v-if="requestParams.states === 2">
        <el-dropdown
            size="small"
            split-button
            @click="toBillContent(row)"
            v-if="requestParams.states === 2"
        >
          查看明细
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div
                  class="w-24 h-10 flex align-items-center"
                  @click="offCancel(row)"
              >
                <i class="el-icon-refresh-right pr-1.5 text-base"></i>
                撤销出账
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div
                  class="w-24 h-10 flex align-items-center"
                  @click="billPay(row)"
              >
                <i class="el-icon-refresh-right pr-1.5 text-base"></i>
                付款
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div v-if="requestParams.states === 3">
        <el-button
            size="small"
            @click="toBillContent(row)"
            v-if="requestParams.states === 3"
        >查看明细
        </el-button>
        <!--        <el-dropdown size="small" split-button @click="toBillContent(row)" v-if="requestParams.states === 3">-->
        <!--          查看明细-->
        <!--          <el-dropdown-menu slot="dropdown">-->
        <!--            <el-dropdown-item>-->
        <!--              <div class="w-24 h-10 flex align-items-center" @click="offCancel(row)">-->
        <!--                <i class="el-icon-refresh-right pr-1.5 text-base"></i>-->
        <!--                撤销出账-->
        <!--              </div>-->
        <!--            </el-dropdown-item>-->
        <!--            <el-dropdown-item>-->
        <!--              <div class="w-24 h-10 flex align-items-center" @click="billPay(row)">-->
        <!--                <i class="el-icon-refresh-right pr-1.5 text-base"></i>-->
        <!--                付款-->
        <!--              </div>-->
        <!--            </el-dropdown-item>-->
        <!--          </el-dropdown-menu>-->
        <!--        </el-dropdown>-->
      </div>
    </template>
  </AppTable>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      typeOneTableColumns: [
        {
          label: '账单号',
          prop: 'billNo',
          search: true
        },
        {
          label: '客户',
          prop: 'customerName',
          search: true
        },
        {
          label: '项目总金额',
          type: 'customSlot',
          slotName: 'money'
        },
        {
          label: '支付方式',
          prop: 'payType',
          type: 'dict'
        },
        // {
        //   label: '出账人',
        //   prop: 'offName'
        // },
        {
          label: '创建时间',
          prop: 'createDtm'
        },
        {
          label: '状态',
          type: 'dict',
          width: 96,
          prop: 'state'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      typeTwoTableColumns: [
        {
          label: '账单号',
          prop: 'billNo',
          search: true
        },
        {
          label: '客户',
          prop: 'customerName',
          search: true
        },
        {
          label: '项目总金额',
          type: 'customSlot',
          slotName: 'money'
        },
        {
          label: '支付方式',
          prop: 'payType',
          type: 'dict'
        },
        {
          label: '出账人',
          prop: 'offName'
        },
        {
          label: '出账时间',
          prop: 'offDtm',
          type: 'customSlot',
          slotName: 'offDtm',
          filterType: 'headerFilterDatePicker',
          datePickerObj: [],
          datePickerKey: [
            'startOffDtm',
            'endOffDtm'
          ]
        },
        {
          label: '状态',
          type: 'dict',
          width: 96,
          prop: 'state'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      typeThreeTableColumns: [
        {
          label: '账单号',
          prop: 'billNo',
          search: true
        },
        {
          label: '客户',
          prop: 'customerName',
          search: true
        },
        {
          label: '项目总金额',
          type: 'customSlot',
          slotName: 'money'
        },
        {
          label: '支付方式',
          prop: 'payType',
          type: 'dict'
        },
        {
          label: '出账人',
          prop: 'offName'
        },
        {
          label: '出账时间',
          prop: 'offDtm',
          type: 'customSlot',
          slotName: 'offDtm',
          filterType: 'headerFilterDatePicker',
          datePickerObj: [],
          datePickerKey: [
            'startOffDtm',
            'endOffDtm'
          ]
        },
        {
          label: '状态',
          type: 'dict',
          width: 96,
          prop: 'state'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      typeFourTableColumns: [
        {
          label: '客户',
          prop: 'customerName',
          search: true
        },
        {
          label: '项目总金额',
          type: 'customSlot',
          slotName: 'money'
        },
        {
          label: '支付方式',
          prop: 'payType',
          type: 'dict'
        },
        {
          label: '出账人',
          prop: 'offName'
        },
        {
          label: '付款时间',
          prop: 'payDtm',
          type: 'customSlot',
          slotName: 'payDtm',
          filterType: 'headerFilterDatePicker',
          datePickerObj: [],
          datePickerKey: [
            'startPayDtm',
            'endPayDtm'
          ]
        },
        {
          label: '出账时间',
          prop: 'offDtm',
          type: 'customSlot',
          slotName: 'offDtm',
          filterType: 'headerFilterDatePicker',
          datePickerObj: [],
          datePickerKey: [
            'startOffDtm',
            'endOffDtm'
          ]
        },
        {
          label: '状态',
          type: 'dict',
          width: 96,
          prop: 'state'
        },
        {
          label: '取消状态',
          type: 'dict',
          width: 96,
          prop: 'cancelState',
          filterType: 'radio',
          filtersList: [
            {
              value: '',
              label: '全部'
            },
            {
              value: 2,
              label: '已取消'
            }
          ]
        },
        {
          label: '退款状态',
          type: 'dict',
          width: 96,
          prop: 'refundState',
          filterType: 'radio',
          filtersList: [
            {
              value: '',
              label: '全部'
            },
            {
              value: 3,
              label: '已退款'
            }
          ]
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      multipleSelection: [],
      requestParams: {
        states: 3,
        payState: '',
        offState: '',
        refundState: '',
        cancelState: '',
        failState: '',
        agUserKey: ''
      },
      stateList: this.$store.getters.state
    }
  },
  watch: {
    customerInfo (obj) {
      this.requestParams.agUserKey = obj.agUserKey
    }
  },
  props: {
    customerInfo: {}
  },
  methods: {
    editBill (item) {
      this.$router.push({
        name: 'billEditor',
        query: {
          id: item.id
        }
      })
    },
    onHandleSelectionChange (list) {
      this.multipleSelection = list
    },
    changeStateType (type) {
      this.requestParams.states = type
    },
    reGetData () {
      this.$refs.AppTable.reGetData()
    },
    /**
     * 根据不同类型渲染不同的table列
     */
    getTableColumns () {
      switch (this.requestParams.states) {
        case 1:
          return this.typeOneTableColumns
        case 2:
          return this.typeTwoTableColumns
        case 3:
          return this.typeThreeTableColumns
        case 4:
          return this.typeFourTableColumns
      }
    },
    billOff (item) {
      this.$api.bill.off({
        id: item.id
      }).then(() => {
        this.$message.success('操作成功')
        this.reGetData()
      })
    },
    offCancel (item) {
      this.$api.bill.offCancel({
        id: item.id
      }).then(() => {
        this.$message.success('操作成功')
        this.reGetData()
      })
    },
    clickDropDown (row) {
      if (row.state === 1) {
        this.billOff(row)
      }
      // else if (row.payState === 1 && row.offState === 2) {
      //   this.billPay(row)
      // }
    },
    toBillContent (item) {
      this.$router.push({
        name: 'billContent',
        query: {
          id: item.id
        }
      })
    },
    billPay (item) {
      this.$api.bill.pay({
        id: item.id
      }).then(() => {
        this.$message.success('操作成功')
        this.reGetData()
      })
    }
  },
  mounted () {
  },
  activated () {
    this.reGetData()
  }
}
</script>
